import React from "react";
import type { ComponentType } from "react";
import ReactDOM from "react-dom";
import { Controller } from "stimulus";
import { BotBuilderApp } from "@circle-react/apps/BotBuilderApp";
import { App } from "@circle-react/apps/MainApp";
import { CommentForm } from "@circle-react/components/CommentsV3/CommentForm";
import { CommentsList } from "@circle-react/components/CommentsV3/CommentsList";
import {
  CommunitySwitcher,
  CommunitySwitcherWithProviders,
} from "@circle-react/components/CommunitySwitcher";
import { CookieStatus } from "@circle-react/components/CookieStatus";
import { DebugSessions } from "@circle-react/components/CustomLogins/DebugSessions";
import { GDPRCookieBanner } from "@circle-react/components/GDPR/GDPRCookieBanner";
import { SearchWrapper as SearchV2 } from "@circle-react/components/HeaderV2/Search/SearchWrapper";
import { HomePageV1 } from "@circle-react/components/HomePageV1";
import { HomeRightSidebar } from "@circle-react/components/HomePageV1/HomeRightSidebar";
import { SuggestedSpacesCarousel } from "@circle-react/components/HomePageV1/SuggestedSpacesCarousel";
import { WelcomeBannerV1 } from "@circle-react/components/HomePageV1/WelcomeBanner";
import { InvitationLinkInformation } from "@circle-react/components/InvitationLink/InvitationLinkInformation";
import { LandingPageSettings } from "@circle-react/components/LandingPageSettings";
import { CommunityOnboardingGettingStartedSectionWrapper } from "@circle-react/components/Layout/CommunityOnboardingGettingStartedSection/CommunityOnboardingGettingStartedSectionWrapper";
import { MemberDirectory } from "@circle-react/components/MemberDirectory";
import { NotificationCount } from "@circle-react/components/NotificationCount";
import { Notifications } from "@circle-react/components/Notifications";
import { NotificationsDropdown } from "@circle-react/components/Notifications/NotificationsDropdown";
import PaywallCoupons from "@circle-react/components/PaywallCoupons";
import { PaywallGroups as PaywallGroupsAdmin } from "@circle-react/components/PaywallGroups/Admin";
import { PaywallPrices } from "@circle-react/components/Paywalls";
import { BulkImportTrialPaywallSubscriptionFormRailsView } from "@circle-react/components/Paywalls/Admin/BulkImportTrialPaywallSubscriptionForm";
import { PaywallChargesAdminIndex } from "@circle-react/components/Paywalls/Admin/PaywallChargesAdmin";
import { PaywallSubscriptionsAdminIndex } from "@circle-react/components/Paywalls/Admin/PaywallSubscriptionsAdmin";
import { PaywallsAdmin } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin";
import { Checkout } from "@circle-react/components/Paywalls/Checkout";
import { PostViewList } from "@circle-react/components/PostsV3/PostViewList";
import { Show } from "@circle-react/components/PostsV3/Show";
import { OpenQPModal } from "@circle-react/components/QuickPost/OpenQPModal";
import { QPEditPost } from "@circle-react/components/QuickPost/QPEditPost";
import RichTextInput from "@circle-react/components/RichTextInput";
import { AnalyticsHeader } from "@circle-react/components/SettingsApp/AnalyticsHeader";
import { LiveStreamSettingsV1 as LiveStreamSettings } from "@circle-react/components/SettingsApp/LiveStreamSettings/LiveStreamSettingsV1";
import { SsoInfoMessage } from "@circle-react/components/SettingsApp/Sso/SsoInfoMessage";
import { CreateSpaceButton } from "@circle-react/components/Spaces/CreateSpaceButton";
import { EditSpaceButton } from "@circle-react/components/Spaces/EditSpaceButton";
import { New } from "@circle-react/components/Spaces/New";
import { CommunityMemberSelect } from "@circle-react/components/shared/uikit/CommunityMemberSelect";
import { withMainAppProviders } from "@circle-react/providers/MainApp/withMainAppProviders";
import { AppBarWrapper } from "@circle-react/wrappers/AppBarWrapper";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { VideoPlayer } from "@circle-react-uikit/VideoPlayer";

const COMPONENT_MAP: Record<string, ComponentType<any>> = {
  RichTextInput,
  CommentsForm: CommentForm,
  CommentsList,
  Notifications,
  NotificationsDropdown,
  NotificationCount,
  CommunitySwitcher,
  CommunitySwitcherWithProviders,
  HomePage: HomePageV1,
  WelcomeBanner: WelcomeBannerV1,
  HomeRightSidebar,
  OpenQPModal,
  QPEditPost,
  SuggestedSpacesCarousel,
  LandingPageSettings,
  CookieStatus,
  SpaceCoverImage: () => null,
  CreateSpaceButton,
  EditSpaceButton,
  PostsPostViewList: PostViewList,
  SearchV2,
  "Spaces.New": New,
  "Posts.Show": Show,
  "Events.New": () => null,
  MemberDirectory,
  App: App,
  CommunityMemberSelect,
  PaywallPrices,
  GDPRCookieBanner,
  Checkout,
  "PaywallCoupons.List": PaywallCoupons.List,
  AppBarWrapper,
  CommunityOnboardingGettingStartedSection:
    CommunityOnboardingGettingStartedSectionWrapper,
  StandaloneTipTapBlockEditor: withMainAppProviders(TipTapBlockEditor),
  PaywallsAdmin,
  PaywallGroupsAdmin,
  PaywallSubscriptionsAdminIndex,
  PaywallChargesAdminIndex,
  BulkImportTrialPaywallSubscriptionFormRailsView,
  InvitationLinkInformation,
  DebugSessions,
  VideoPlayer,
  LiveStreamSettings,
  AnalyticsHeader,
  SsoInfoMessage,
  BotBuilderApp,
};

const jsonParse = (value: unknown): Record<string, unknown> => {
  if (typeof value !== "string") {
    return {};
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
};

export default class extends Controller {
  override connect() {
    if (!(this.element instanceof HTMLElement)) {
      console.error("Error: element is not an HTMLElement");
      return;
    }

    const { component: componentName, props: stringProps } =
      this.element.dataset;

    const props = jsonParse(stringProps);
    const Component = componentName ? COMPONENT_MAP[componentName] : null;

    if (!Component) {
      console.error(`Error: invalid component - ${componentName}`);
      return;
    }

    ReactDOM.render(React.createElement(Component, props), this.element);
  }

  override disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}
