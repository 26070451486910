import classNames from "classnames";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";

interface RailbarDraggerProps {
  startResizing: () => void;
  isMessagingModalOpen: boolean;
  isV3Enabled: boolean;
  isTrialBannerExists: boolean;
}

export const RailbarDragger = ({
  startResizing,
  isMessagingModalOpen,
  isV3Enabled,
  isTrialBannerExists,
}: RailbarDraggerProps) => {
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();

  return (
    //eslint-disable-next-line jsx-a11y/no-static-element-interactions -- Interactions are not required for this component
    <div
      className={classNames(
        `border-primary hover:bg-circle invisible z-10 shrink-0 grow-0 basis-0.5 cursor-col-resize resize-x justify-self-end hover:w-0.5 md:visible`,
        {
          "h-[calc(100vh-40px)]": isMessagingModalOpen,
          "h-[calc(100vh-64px)]":
            isV3Enabled && !isMessagingModalOpen && !isTrialBannerExists,
          "h-[calc(100vh-64px-48px)]":
            isV3Enabled &&
            !isMessagingModalOpen &&
            !isTrialBannerExists &&
            isMasqueradingEnabledAndActive,
          "h-[calc(100vh-48px)]":
            !isV3Enabled && !isMessagingModalOpen && !isTrialBannerExists,
          "h-[calc(100vh-112px)]":
            isV3Enabled && !isMessagingModalOpen && isTrialBannerExists,
          "h-[calc(100vh-96px)]":
            !isV3Enabled &&
            !isMessagingModalOpen &&
            isTrialBannerExists &&
            !isMasqueradingEnabledAndActive,
          "h-[calc(100vh-144px)]":
            !isV3Enabled &&
            !isMessagingModalOpen &&
            isTrialBannerExists &&
            isMasqueradingEnabledAndActive,
          "h-[calc(100vh-144px-48px)]":
            isV3Enabled &&
            !isMessagingModalOpen &&
            isTrialBannerExists &&
            isMasqueradingEnabledAndActive,
        },
      )}
      onMouseDown={startResizing}
    />
  );
};
