import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { PreviewBarComponent } from "./PreviewBarComponent";

interface OptInBarProps {
  previewBarText: string;
  previewBarCtaContent: string;
}

export const PreviewBar = ({
  previewBarText,
  previewBarCtaContent,
}: OptInBarProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isCurrentUserCommunityAdmin = isCommunityAdmin(currentCommunityMember);

  return (
    <PreviewBarComponent
      previewBarText={previewBarText}
      previewBarCtaContent={previewBarCtaContent}
      isAdmin={isCurrentUserCommunityAdmin}
    />
  );
};
