import type { ReactNode } from "react";
import { createContext, useContext } from "react";

const ForceV3Context = createContext(false);
ForceV3Context.displayName = "ForceV3Context";

export interface ForceV3Props {
  children: ReactNode;
}

// TODO: remove this context
export const ForceV3 = ({ children }: ForceV3Props) => (
  <ForceV3Context.Provider value>{children}</ForceV3Context.Provider>
);

export const useForceV3Context = () => useContext(ForceV3Context);
